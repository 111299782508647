<template>
    <div id="wangeditor">
        <div ref="editorElem" style="text-align:left"></div>
    </div>  
</template>
<script>
import E from 'wangeditor'
export default {
  name: 'editorElem',
  data () {
    return {
      editor: null,
      editorContent: ''
    }
  },
  props: ['catchData', 'content'],    // 接收父组件的方法
  watch: {
    content () {
      this.editor.txt.html(this.content)
    }
  },
  mounted () {
    this.editor = new E(this.$refs.editorElem)

    this.editor.config.height = 140
    this.editor.config.placeholder = '请输入要发表的内容';
    this.editor.config.onchange = (html) => {
      this.editorContent = html
      this.catchData(this.editorContent)  // 把这个html通过catchData的方法传入父组件
    }
    // this.editor.uploadImgServer = '你的上传图片的接口'
    // this.editor.uploadFileName = '你自定义的文件名'
    
    this.editor.config.showFullScreen = false;
    this.editor.config.menus = [
        // 'emoticon',  // 表情
        // 'fontSize',  // 字号
        // 'bold',
        // 'underline',
        // 'justify',  // 对齐方式
        // 'strikeThrough',  // 删除线
        // 'link',
    ]
    this.editor.config.emotions = [
        // {
        //     title: '默认', // tab 的标题
        //     type: 'image', // 'emoji' 或 'image' ，即 emoji 形式或者图片形式
        //     content: [
        //         { alt: '', src: require('../assets/face/1.gif') },
        //         { alt: '', src: require('../assets/face/2.gif') },
        //         { alt: '', src: require('../assets/face/3.gif') },
        //         { alt: '', src: require('../assets/face/4.gif') },
        //         { alt: '', src: require('../assets/face/5.gif') },
        //         { alt: '', src: require('../assets/face/6.gif') },
        //         { alt: '', src: require('../assets/face/7.gif') },
        //         { alt: '', src: require('../assets/face/8.gif') },
        //         { alt: '', src: require('../assets/face/9.gif') },
        //         { alt: '', src: require('../assets/face/10.gif') },
        //         { alt: '', src: require('../assets/face/11.gif') },
        //         { alt: '', src: require('../assets/face/12.gif') },
        //         { alt: '', src: require('../assets/face/13.gif') },
        //         { alt: '', src: require('../assets/face/14.gif') },
        //         { alt: '', src: require('../assets/face/15.gif') },
        //         { alt: '', src: require('../assets/face/16.gif') },
        //         { alt: '', src: require('../assets/face/17.gif') },
        //         { alt: '', src: require('../assets/face/18.gif') },
        //         { alt: '', src: require('../assets/face/19.gif') },
        //         { alt: '', src: require('../assets/face/20.gif') },
        //         { alt: '', src: require('../assets/face/21.gif') },
        //         { alt: '', src: require('../assets/face/22.gif') },
        //         { alt: '', src: require('../assets/face/23.gif') },
        //         { alt: '', src: require('../assets/face/24.gif') },
        //         { alt: '', src: require('../assets/face/25.gif') },
        //         { alt: '', src: require('../assets/face/26.gif') },
        //         { alt: '', src: require('../assets/face/27.gif') },
        //         { alt: '', src: require('../assets/face/28.gif') },
        //         { alt: '', src: require('../assets/face/29.gif') },
        //         { alt: '', src: require('../assets/face/30.gif') },
        //         { alt: '', src: require('../assets/face/31.gif') },
        //         { alt: '', src: require('../assets/face/32.gif') },
        //         { alt: '', src: require('../assets/face/33.gif') },
        //         { alt: '', src: require('../assets/face/34.gif') },
        //         { alt: '', src: require('../assets/face/35.gif') },
        //         { alt: '', src: require('../assets/face/36.gif') },
        //         { alt: '', src: require('../assets/face/37.gif') },
        //         { alt: '', src: require('../assets/face/38.gif') },
        //         { alt: '', src: require('../assets/face/39.gif') },
        //         { alt: '', src: require('../assets/face/40.gif') },
        //         { alt: '', src: require('../assets/face/41.gif') },
        //         { alt: '', src: require('../assets/face/42.gif') },
        //         { alt: '', src: require('../assets/face/43.gif') },
        //         { alt: '', src: require('../assets/face/44.gif') },
        //         { alt: '', src: require('../assets/face/45.gif') },
        //         { alt: '', src: require('../assets/face/46.gif') },
        //         { alt: '', src: require('../assets/face/47.gif') },
        //         { alt: '', src: require('../assets/face/48.gif') },
        //         { alt: '', src: require('../assets/face/49.gif') },
        //         { alt: '', src: require('../assets/face/50.gif') },
        //         { alt: '', src: require('../assets/face/51.gif') },
        //         { alt: '', src: require('../assets/face/52.gif') },
        //         { alt: '', src: require('../assets/face/53.gif') },
        //         { alt: '', src: require('../assets/face/54.gif') },
        //         { alt: '', src: require('../assets/face/55.gif') },
        //         { alt: '', src: require('../assets/face/56.gif') },
        //         { alt: '', src: require('../assets/face/57.gif') },
        //         { alt: '', src: require('../assets/face/58.gif') },
        //         { alt: '', src: require('../assets/face/59.gif') },
        //         { alt: '', src: require('../assets/face/60.gif') },
        //         { alt: '', src: require('../assets/face/61.gif') },
        //         { alt: '', src: require('../assets/face/62.gif') },
        //         { alt: '', src: require('../assets/face/63.gif') },
        //         { alt: '', src: require('../assets/face/64.gif') },
        //         { alt: '', src: require('../assets/face/65.gif') },
        //         { alt: '', src: require('../assets/face/66.gif') },
        //         { alt: '', src: require('../assets/face/67.gif') },
        //         { alt: '', src: require('../assets/face/68.gif') },
        //         { alt: '', src: require('../assets/face/69.gif') },
        //         { alt: '', src: require('../assets/face/70.gif') },
        //         { alt: '', src: require('../assets/face/71.gif') },
        //         { alt: '', src: require('../assets/face/72.gif') },
        //         { alt: '', src: require('../assets/face/73.gif') },
        //         { alt: '', src: require('../assets/face/74.gif') },
        //         { alt: '', src: require('../assets/face/75.gif') },
        //         { alt: '', src: require('../assets/face/76.gif') },
        //         { alt: '', src: require('../assets/face/77.gif') },
        //         { alt: '', src: require('../assets/face/78.gif') },
        //         { alt: '', src: require('../assets/face/79.gif') },
        //         { alt: '', src: require('../assets/face/80.gif') },
        //         { alt: '', src: require('../assets/face/81.gif') },
        //         { alt: '', src: require('../assets/face/82.gif') },
        //         { alt: '', src: require('../assets/face/83.gif') },
        //         { alt: '', src: require('../assets/face/84.gif') },
        //         { alt: '', src: require('../assets/face/85.gif') },
        //         { alt: '', src: require('../assets/face/86.gif') },
        //         { alt: '', src: require('../assets/face/87.gif') },
        //         { alt: '', src: require('../assets/face/88.gif') },
        //         { alt: '', src: require('../assets/face/89.gif') },
        //         { alt: '', src: require('../assets/face/90.gif') },
        //         { alt: '', src: require('../assets/face/91.gif') },
        //         { alt: '', src: require('../assets/face/92.gif') },
        //         { alt: '', src: require('../assets/face/93.gif') },
        //         { alt: '', src: require('../assets/face/94.gif') },
        //         { alt: '', src: require('../assets/face/95.gif') },
        //         { alt: '', src: require('../assets/face/96.gif') },
        //         { alt: '', src: require('../assets/face/97.gif') },
        //         { alt: '', src: require('../assets/face/98.gif') },
        //         { alt: '', src: require('../assets/face/99.gif') },
        //         { alt: '', src: require('../assets/face/100.gif') },
        //     ]
        // },
        {
            title: 'emoji',  // tab 的标题
            type: 'emoji', // 'emoji' / 'image'
            // emoji 表情，content 是一个数组即可
            content: '😀 😃 😄 😁 😆 😅 😂 😊 😇 🙂 🙃 😉 😓 😪 😴 🙄 🤔 😬 🤐 😍 😘 😙 🤗 😎 😡 😱 😷 🤪 🤩 🤬 🤮 🥰 🙈 🙉 👀 💤 💩 👏 👍 👎 👊 🤝 👌 💪 👆 👇 👈 👉 👨‍💻 👩‍💻 🌺 🌹 🌼 🌧 💦 🍎 🍻 🍺 🧧 ❌ ❗️ ❓ 💯 ✅ ❎ 🔘 🇨🇳'.split(/\s/),
        }
    ]
    // this.editor.menus = [          // 菜单配置
    //   'head',  // 标题
    //   'bold',  // 粗体
    //   'fontSize',  // 字号
    //   'fontName',  // 字体
    //   'italic',  // 斜体
    //   'underline',  // 下划线
    //   'strikeThrough',  // 删除线
    //   'foreColor',  // 文字颜色
    //   'backColor',  // 背景颜色
    //   'link',  // 插入链接
    //   'list',  // 列表
    //   'justify',  // 对齐方式
    //   'quote',  // 引用
    //   'emoticon',  // 表情
    //   'image',  // 插入图片
    //   'table',  // 表格
    //   'code',  // 插入代码
    //   'undo',  // 撤销
    //   'redo'  // 重复
    // ]
    

    this.editor.create()     // 创建富文本实例
    if (!this.content) {
      // this.editor.txt.html('请编辑内容1')
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/scss">
    #wangeditor {
      width: 50rem;
    }  
</style>